import React, { useState, useEffect, useRef } from 'react'
import Slider from "react-slick";

import { Wrapper, Inner } from './Styles'
import Heading from '../heading'

const Carousel = ({ features, topHeader, header }) => {
    const [data, setData] = useState(features)

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, []);

    const settings = {
        infinite: true,
        centerMode: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1624,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const settings2 = {
        infinite: true,
        centerMode: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false
    };

    return (
        <Wrapper>
            <Heading id="features" top={topHeader} header={header} />
            <div>
                <Slider {...settings} ref={slider1} asNavFor={nav2}>
                    {features.map((feature, i) => (
                        <Inner key={i}>
                            <img loading="lazy" src={process.env.PUBLIC_URL + feature.image} alt={feature.alt} />
                        </Inner>
                    ))}
                </Slider>
                <Slider {...settings2} ref={slider2} asNavFor={nav1}>
                    {features.map((feature, i) => (
                        <div key={i} className="slickContent">
                            <h3>{feature.name}</h3>
                            <p>{feature.text}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </Wrapper >
    )
}

export default Carousel
