import React from 'react'
import { useTranslation } from 'react-i18next'

import {
    Landing, Introduction, Download, Kooperation,
    Numbers, Reviews, Carousel
} from '../components'

const Home = () => {
    const [t] = useTranslation()

    const getData = (key) => {
        return t(key, { returnObjects: true })
    }

    return (
        <div style={{ backgroundColor: "#fff" }}>
            <>
                <Landing data={getData("landing")} partners={getData("partners")} />
                <Introduction text={getData("introduction")} />
                <Download data={getData("download")} />
                <Carousel features={getData('features')} topHeader={getData("featuresTopHeader")} header={getData("featuresHeader")}/>
                <Numbers data={getData("numbers")} topHeader={getData("numbersTopHeader")} header={getData("numbersHeader")} />
                <Kooperation data={getData("kooperation")} />
                <Reviews data={getData("reviews")} topHeader={getData("reviewsTopHeader")} header={getData("reviewsHeader")} />
            </>
        </div>
    )
}

export default Home