import styled from "styled-components";

export const Wrapper = styled.div`
	padding: 6rem 0;
	/* min-height: 516px; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #f5f5f5;
`;

export const Cards = styled.div`
	padding-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	max-width: 100vw;
`;

export const Card = styled.div`
	/* height: 250px; */
	background: ghostwhite;
	width: 950px;
	max-width: 100vw;
	transform: scale(0.75);
	margin: 1rem;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
	transition: 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	& img {
		position: absolute;
		top: -35px;
		height: 70px;
		border-radius: 50%;
		border: 1px solid gray;
		background: ghostwhite;
	}
	& span {
		padding: 2rem;
		margin-top: 2rem;
		text-align: center;
	}
	& span:last-child {
		font-weight: 600;
		color: var(--red);
		padding-top: 0;
		margin: 0;
	}
	@media screen and (min-width: 1612px) {
		max-width: 500px;
	}
`;
