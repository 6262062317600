import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { Wrapper, ButtonContainer, DeclineButton, AcceptButton } from './Styles'

const CookiePopup = ({setPopupShown}) => {
    const [cookieConsent, setCookieConsent] = useState(false);
    const [t] = useTranslation();

    useEffect(() => {
        if(cookieConsent){
            localStorage.setItem('PopupShown', 'True');
            setPopupShown(true);
        }
    }, [cookieConsent])

    return (
        <Wrapper>
            <span>{t("cookie.text")}</span>
            <ButtonContainer>
                <DeclineButton onClick={() => setPopupShown(true)}>{t("cookie.block")}</DeclineButton>
                <AcceptButton onClick={() => setCookieConsent(true)}>{t("cookie.accept")}</AcceptButton>
            </ButtonContainer>
        </Wrapper>
    )
}

export default CookiePopup
