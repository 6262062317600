import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

import { Wrapper, Content, Copyright, Links } from './Styles'

const Footer = () => {
    const [t] = useTranslation();

    const scrollHome = () => {
        window.scrollTo({ top: 0 });
    };

    return (
        <Wrapper>
            <Content>
                <Copyright>
                    ©
                    <strong> {new Date().getFullYear()} </strong>
                        {t('footer.text')}
                    <a className="extlink" href="https://www.ris.at" rel="noopener noreferrer" target="_blank">RIS GmbH</a>
                </Copyright>
                <Links>
                    <Link onClick={scrollHome} to={t("footer.datenschutzUrl")}>{t('footer.datenschutz')}</Link>
                    -
                    <Link onClick={scrollHome} to={t("footer.impressumUrl")}>{t('footer.appImpressum')}</Link>
                    -
                    <Link onClick={scrollHome} to={t("footer.webImpressumUrl")}>{t('footer.webImpressum')}</Link>
                </Links>
            </Content>
        </Wrapper>
    )
}

export default Footer