import React from 'react'
import LazyLoad from 'react-lazyload'

import { Wrapper, Cards, Card } from './Styles'
import Heading from '../heading'

const Reviews = ({ data, topHeader, header }) => {
    return (
        <Wrapper>
            <Heading top={topHeader} header={header} />
            <LazyLoad  offset={300}>
                <Cards>
                    {//randomize the array and slice it so it only shows 3 random reviews
                        data.sort(() => (0.5 - Math.random())).slice(0, 3).map((review, i) => (
                            <Card className={(i % 2) === 1 ? "bigCard" : ""} key={i}>
                                <img loading="lazy" src={process.env.PUBLIC_URL + review.image} alt={review.alt} />
                                <span>{review.text}</span>
                                <span>- {review.name}</span>
                            </Card>
                        ))}
                </Cards>
            </LazyLoad>
        </Wrapper>
    )
}

export default Reviews
