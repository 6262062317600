import React from 'react'
import { useTranslation } from 'react-i18next'
import { Landing, Content } from './Styles'
import DOMPurify from 'dompurify';

const WebImprint = () => {
    const { t } = useTranslation();

    return (
        <div style={{ minHeight: "150vh", backgroundColor: "#fff" }}>
            <Landing>
                <h1>{t("web-impressum.header")}</h1>
            </Landing>
            <Content>
                <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t("web-impressum.content"), { ADD_ATTR: ['target'] }) }}></div>
            </Content>
        </div>
    )
}

export default WebImprint
