import styled from "styled-components";

export const Landing = styled.div`
	padding-top: 15rem;
	background-image: linear-gradient(to right, rgba(155, 155, 0, 0.6), rgba(153, 155, 55, 0.8)),
		url(${(props) => props.imgUrl});
	background-size: cover;
	/* background-attachment: fixed; */
	height: 320px;
	padding: 0 2rem;
	margin: 0 auto;
	flex-wrap: wrap;
	color: white;
	& h1 {
		color: white;
		margin: 0 auto;
		padding-bottom: 1rem;
		padding-top: 10rem;
		max-width: 1600px;
	}
`;

export const Content = styled.div`
	width: 100%;
	max-width: 1600px;
	padding: 2rem 0;
	margin: 0 auto;
	a, h3 {
		color: var(--red);
	}
`;
