import React, { useEffect } from "react";
import { Routes, Route } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";

import Wrapper from "./pages/Wrapper";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import AppImprint from "./pages/AppImprint";
import WebImprint from "./pages/WebImprint";

import { useTranslation } from "react-i18next";

function App() {
	let navigate = useNavigate();
	const location = useLocation();

	const { t, i18n } = useTranslation();

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	useEffect(() => {
		if (location.pathname === "/") {
			if (navigator.language.includes("it")) {
				return navigate("/Gem2Go_Alto_Adige");
			} else {
				return navigate("/Gem2Go_Suedtirol");
			}
		} else if (location.pathname.includes("/Gem2Go_Alto_Adige")) {
			changeLanguage("it");
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>{t("htmlTitle")}</title>
			</Helmet>
			<Routes>
				<Route path='/Gem2Go_Suedtirol' element={<Wrapper />}>
					<Route path='/' element={<Home />} />
					<Route path='/datenschutz' element={<Privacy />} />
					<Route path='/impressum' element={<AppImprint />} />
					<Route path='/web-impressum' element={<WebImprint />} />
				</Route>
				<Route path='/Gem2Go_Alto_Adige' element={<Wrapper />}>
					<Route path='/' element={<Home />} />
					<Route path='/privacy' element={<Privacy />} />
					<Route path='/note-legali' element={<AppImprint />} />
					<Route path='/web-note-legali' element={<WebImprint />} />
				</Route>
				<Route path='*' render={() => <div>404</div>} />
			</Routes>
		</>
	);
}

export default App;
