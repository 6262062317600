import React from 'react'
import LazyLoad from 'react-lazyload'
import { isBrowser, isIOS, isAndroid } from 'react-device-detect'

import { Wrapper, Content, Container, Links } from './Styles'

import { ReactComponent as Apple } from './appstore.svg';
import { ReactComponent as Android } from './playstore.svg';

const Download = ({ data }) => {
    return (
        <LazyLoad offset={300} height={320}>
            <Wrapper id="download" imgUrl={process.env.PUBLIC_URL + '/assets/Wallpaper.webp'}>
                <Content>
                    <Container>
                        <h2>{data.header}</h2>
                        <span>{data.text}</span>
                        <Links>
                            {(isIOS || isBrowser) &&
                                <a href={`https://apps.apple.com/${data.country}/app/gem2go-sudtirol/id1050103476`} rel="noopener noreferrer" target="_blank">
                                    <Apple />
                                    <span>App Store</span>
                                </a>
                            }
                            {(isAndroid || isBrowser) &&
                                <a href="https://play.google.com/store/apps/details?id=com.ris.gem2go12de" rel="noopener noreferrer" target="_blank">
                                    <Android />
                                    <span>Google Play</span>
                                </a>
                            }
                        </Links>
                    </Container>
                    <img src={process.env.PUBLIC_URL + '/assets/iphone2-alt.webp'} alt={data.altTextImg} />
                </Content>
            </Wrapper>
        </LazyLoad>
    )
}

export default Download