import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';
import { Link } from "react-router-dom";

import { NavWrapper, Nav } from './Styles'
import Burger from './Burger'
import ScrollArrow from './ScrollArrow'

const Navbar = ({ showPowerspeed }) => {
    const [navbarOnTop, setNavbarOnTop] = useState(true);

    const changeBackground = () => {
        if (window.scrollY <= 100) {
            setNavbarOnTop(true);
        } else {
            setNavbarOnTop(false);
        }
    }

    useEffect(() => {
        const throttledCount = throttle(changeBackground, 250);
        window.addEventListener('scroll', throttledCount);
        return () => window.removeEventListener('scroll', throttledCount);
    }, []);

    return (
        <NavWrapper toggle={navbarOnTop}>
            <Nav>
                {!navbarOnTop && ReactDOM.createPortal(
                    <ScrollArrow />,
                    document.getElementById('root')
                )}

                <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="" className="navImgLink">
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="GEM2GO Südtirol Logo" />
                </Link>
                <Burger showPowerspeed={showPowerspeed} />
            </Nav >
        </NavWrapper>
    )
}

export default Navbar