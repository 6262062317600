import React from 'react'

import { Background, Wrapper, Block, Link } from './Styles'
import Heading from '../heading'

function Kooperation({data}) {
    return (
        <Background>
            <Wrapper>
                <Heading header={data.header}/>
                <img src={process.env.PUBLIC_URL + '/assets/gvcc-logo.webp'} alt="GVCC Logo" />
                <Link href="https://www.gvcc.net/de" target='_blank' rel='noreferer'>WWW.GVCC.NET</Link>
                <span>{data.adress}</span>
                <span>+39 0471 304655</span>
                <Link href="mailto:info@gvcc.net">info@gvcc.net</Link>
            </Wrapper>
        </Background>
    )
}

export default Kooperation
