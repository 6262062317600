import React from 'react'
import { useTranslation } from 'react-i18next'
import { Landing, Content } from './Styles'
import DOMPurify from 'dompurify';

const Privacy = () => {

    const [t] = useTranslation();

    return (
        <div style={{ minHeight: "140vh", backgroundColor: "#fff" }}>
            <Landing>
                <h1>{t("datenschutz.header")}</h1>
            </Landing>
            <Content>
                <h2>{t("datenschutz.paragraph")}</h2>
                <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t("datenschutz.content")) }}></div>
            </Content>
        </div>
    )
}

export default Privacy