import React from 'react'
import { HashLink } from 'react-router-hash-link';
import DOMPurify from 'dompurify';
import LazyLoad from 'react-lazyload'

import Heading from '../heading'
import { Wrapper, Container } from './Styles'

const Introduction = ({ text }) => {
    return (
        <div id="app">
            <Heading top={text.topHeader} header={text.header} />
            <LazyLoad  offset={300}>
                <Container>
                    <img src={process.env.PUBLIC_URL + 'assets/multiplePhones.webp'} alt={text.altTextImg} />
                    <Wrapper>
                        <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text.appText) }}></div>
                        <HashLink smooth to="#features">{text.linkText}</HashLink>
                    </Wrapper>
                </Container>
            </LazyLoad>
        </div>
    )
}

export default Introduction
