import styled from "styled-components";

export const Wrapper = styled.footer`
  bottom: 0;
  width: 100%;
  min-height: 3.5rem;
  background-color: var(--red);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

`;

export const Content = styled.div`
  width: 100vw;
  max-width: 1600px;
  display: flex;
	align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white;
  padding: 0.5rem;
  & a {
    color: white;
    padding: 0 1ch;
    text-decoration: none;
  }
  & a:hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled.div`
  padding: 0.5rem;
`;

export const Links = styled.div`
  padding: 0.5rem 0;
`;
