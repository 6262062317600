import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  & img {
    width: 400px;
    max-width: 70%;
    height: auto;
    /* object-fit: contain; */
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 2rem 0;
  max-width: 900px;
  & h1 {
    margin: 0;
  }
  & div {
    max-width: 1200px;
  }
  & a {
    background-color: var(--red);
    color: white;
    border: 2px solid var(--red);
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: 0.3s ease;
    @media screen and (max-width: 680px) {
			margin: 0 auto;
		}
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: white;
        color: var(--red);
      }
    }
  }
`;
