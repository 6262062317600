import React, { useState } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor';
import LazyLoad from 'react-lazyload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserFriends, faMapMarkedAlt, faBell, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

import { Wrapper, NumberWrapper } from './Styles'
import Heading from '../heading'

library.add(faUserFriends, faMapMarkedAlt, faBell, faTachometerAlt);

const Numbers = ({ data, topHeader, header }) => {
    const [focus, setFocus] = useState(false);

    return (
        <Wrapper imgUrl={process.env.PUBLIC_URL + '/assets/map.webp'}>
            <Heading id="numbers" top={topHeader} header={header} isWhite />
            <LazyLoad  offset={300}>
                <NumberWrapper>
                    {data.map((item, index) => (
                        <div key={index}>
                            <FontAwesomeIcon icon={['fa', item.icon]} size="lg" alt="" role="presentation"/>
                            <CountUp start={focus ? 0 : null} end={item.number} duration={1.5} separator="." >
                                {({ countUpRef }) => (
                                    <VisibilitySensor
                                        onChange={(isVisible) => { if (isVisible) setFocus(true) }}
                                        delayedCall
                                    >
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <span>{item.name}</span>
                        </div>
                    ))}
                </NumberWrapper>
            </LazyLoad>
        </Wrapper>
    )
}

export default Numbers