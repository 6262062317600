import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { ScrollButton } from './Styles'

library.add(faArrowUp);

const ScrollArrow = () => {
  const [t] = useTranslation();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ScrollButton tabindex="1111" onClick={scrollTop}>
      <FontAwesomeIcon icon={['fa', 'arrow-up']} size="2x" alt={t('alt-ScrollArrow')} role="navigation"/>
    </ScrollButton>
  );
}

export default ScrollArrow;