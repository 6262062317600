import styled from "styled-components";

export const Background = styled.div`
	/* background: linear-gradient(90deg, rgb(204, 8, 57) 20%, rgb(77, 26, 148) 65%); */
	box-sizing: border-box;
	/* @media screen and (max-width: 1150px) {
		background: linear-gradient(
			180deg,
			rgb(204, 8, 57) 20%,
			rgb(77, 26, 148) 65%
		);
	} */
`;

export const Wrapper = styled.div`
	box-sizing: border-box;
	max-width: 1600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: .5rem;
	padding: 5rem 2rem;
	/* color: #fff; */
	@media screen and (max-width: 1150px) {
		flex-wrap: wrap;
	}
	img {
		max-width: 100%;
	}
	span {
		text-align: center;
	}
`;

export const Link = styled.a`
	/* color: #fff; */
	/* text-decoration: none; */
	/* border: 1px solid white; */
	/* border-radius: 10px; */
	/* padding: 0.5rem 1rem; */
	/* margin: 1rem 1rem 0 0; */
	color: var(--red);
	transition: 0.3s ease;
	:hover {
		background-color: white;
		color: var(--dark-green);
	}
`;
