import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import { Wrapper } from './Styles'

const RightNav = ({ open, setOpen }) => {
    const [link, setLink] = useState("/");
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const scrollHome = (open) => {
        setOpen(!open);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (i18n.language === "de") {
            setLink("/Gem2Go_Alto_Adige")
        } else if (i18n.language === "it") {
            setLink("/Gem2Go_Suedtirol")
        }
        if (location.pathname === "/Gem2Go_Alto_Adige") {
			changeLanguage("it");
		}
    }, [i18n])


    const changeLanguage = () => {
        if (i18n.language === "de") {
            i18n.changeLanguage("it")
            setLink("/Gem2Go_Suedtirol")
        } else if (i18n.language === "it") {
            i18n.changeLanguage("de")
            setLink("/Gem2Go_Alto_Adige")
        }
        setOpen(!open);
    }


    return (
        <Wrapper open={open}>
            <Link onClick={() => scrollHome(open)} to=''>{t('nav.home')}</Link>
            <HashLink onClick={() => setOpen(!open)} smooth to="#app">GEM2GO</HashLink>
            <HashLink onClick={() => setOpen(!open)} smooth to="#features">{t('nav.features')}</HashLink>
            <HashLink onClick={() => setOpen(!open)} smooth to="#numbers">{t('nav.numbers')}</HashLink>
            <Link onClick={changeLanguage} to={link}>{t("nav.language")}</Link>
            <HashLink onClick={() => setOpen(!open)} smooth to="#download" className="button">{t('nav.download')}</HashLink>
        </Wrapper>
    )
}

export default RightNav